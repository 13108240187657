<template>
    <div>
        <navheader></navheader>
        <div>
            <div class="examBox">
                <div class="examContent">
                    <div class="crumbBox">
                        <p><i></i><a href="/">首页</a> > <a href="/#/QuestionBank">题库</a> > <span>{{showquestion.practiceTitle}}</span></p>
                    </div>
                    <div class="titleBox">
                        <p class="title-top">
                            {{showquestion.practiceTitle}}
                        </p>
                        <p class="title-sub">
                            <span>总题数：{{showquestion.totalPractice}}</span><span>答题数：{{showquestion.answerNum}}</span><span>正题：{{showquestion.trueCount}}</span><span>错题：{{showquestion.errorCount}}</span><span>未答：{{showquestion.unansweredNum}}</span>
                        </p>
                    </div>
                    <div class="examContentBox" id="examContentBox">
                        <div class="leftBox">
                            <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
                                <div class="questionContent" v-for="(item, index) in showList" :key="item.id" :id="'id'+index">
                                    <div class="question-title">
                                        <p style="display: inline-block;">
                                            <span class="font-bold" v-if="item.qType == 'single'">【单选题】</span>
                                            <span class="font-bold" v-if="item.qType == 'multi'">【多选题】</span>
                                            <span class="font-bold" v-if="item.qType == 'answer'">【问答题】</span>
                                            <span class="font-bold" v-if="item.qType == 'judge'">【判断题】</span>
                                            <span v-html="item.subject">{{item.subject}}</span>
                                        </p>
                                    </div>
                                    <div class="VSTD_box_item_select" v-if="item.qType == 'single'">
                                        <el-form-item label="" prop="resource">
                                            <el-radio-group v-model="ruleForm.resource[index].answer">
                                                <!-- <el-radio v-for="it of item.contList" :key="it.id" :label="it.optionValue" disabled>{{it.option | filterHtml}}</el-radio> -->
                                                <el-radio v-for="it of item.contList" :key="it.id" :label="it.optionValue" disabled v-html="it.option">{{it.option}}</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div :class="item.qType == 'multi'? 'VSTD_box_item_mutil':'VSTD_box_item_select'" v-if="item.qType == 'multi'">
                                        <el-form-item label="" prop="resource">
                                            <el-checkbox-group v-model="ruleForm.resource[index].answerList">
                                                <el-checkbox class="answer-checkbox" v-for="it of item.contList" :key="it.id" :label="it.optionValue" disabled v-html="it.option">{{it.option}}</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </div>
                                    <div :class="item.qType == 'multi'? 'VSTD_box_item_mutil':'VSTD_box_item_select'" v-if="item.qType == 'answer'">
                                        <div class="answerBox">
                                            <p class="answerTitle">我的答案：</p>
                                            <p v-if="item.selectAnswer == null" class="answerError">
                                                未作答
                                            </p>
                                            <p v-else class="answerText" v-html="item.selectAnswer">
                                                {{item.selectAnswer}}
                                            </p>
                                        </div>
                                    </div>
                                    <div :class="item.qType == 'multi'? 'VSTD_box_item_mutil':'VSTD_box_item_select'" v-if="item.qType == 'judge'">
                                        <el-form-item label="" prop="resource">
                                            <el-radio-group v-model="ruleForm.resource[index].answer">
                                                <el-radio v-for="it of item.contList" :key="it.id" :label="it.optionValue" disabled v-html="it.option">{{it.option}}</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                    <div v-if="item.qType == 'answer'" class="answerTwoBox">
                                        <div class="answerTop">
                                            <p>知识点：<span class="answerTwoBoxSpan" v-html="item.knowledge">{{item.knowledge}}</span></p>
                                            <p>难度：<span class="answerTwoBoxSpan" v-html="item.difficulty">{{item.difficulty}}</span></p>
                                            <p>解析：<span class="answerTwoBoxSpan" v-html="item.analysis">{{item.analysis}}</span></p>
                                        </div>
                                        <div class="answerBottom">
                                            <p>正确答案：<span v-html="item.answer">{{item.answer}}</span></p>
                                        </div>
                                    </div>
                                    <div class="answerBox" v-else>
                                        <div class="answerTop">
                                            <div class="answerLeft">
                                                <p>正确答案：<span class="right" v-html="item.answer">{{item.answer}}</span></p>
                                            </div>
                                            <div class="answerRight">
                                                <p>
                                                    我的答案：
                                                    <span class="error" v-if="item.selectAnswer == null || item.selectAnswer.length == 0">未作答</span>
                                                    <!-- <span class="error" v-if="item.selectAnswer.length == 0"></span> -->
                                                    <!-- <span class="error" v-if="item.isTrue == 0">{{item.selectAnswer}}</span>
                                                    <span class="right" v-if="item.isTrue == 1">{{item.selectAnswer}}</span> -->
                                                    <span class="error" v-if="item.isTrue == 0">{{item.selectAnswerStr}}</span>
                                                    <span class="right" v-if="item.isTrue == 1">{{item.selectAnswerStr}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="answerContent">
                                            <div class="answerSlide">
                                                <p v-if="item.knowledge != ''">知识点：<span v-html="item.knowledge">{{item.knowledge}}</span></p>
                                            </div>
                                            <div class="answerSlide" style="display: none;">
                                                <p>试题难度：<span class="slide"><star :size="48" :score="item.difficulty"></star></span><span class="slide" style="display: none;">本题共被作答<span class="color">{{item.beAnswered}}</span>次</span><span>正确率：<span class="color">{{item.accuracy}}</span></span></p>
                                                
                                            </div>
                                            <div class="answerSlide">
                                                <p>参考解析：</p>
                                                <div class="span" v-html="item.analysis">{{item.analysis}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                        <div class="rightBox" :class="{suspension:isfixTab}">
                            <div class="card-content-list">
                                <div class="timer-box">
                                    <div v-if="showquestion.sType == 0">
                                        <p>答题时间：<span>{{showquestion.answer_time}}</span></p>
                                    </div>
                                    <div v-else>
                                        <p>答题用时：<span>{{showquestion.useTime}}分钟</span></p>
                                        <p>答题时间：<span>{{showquestion.answer_time}}</span></p>
                                    </div>
                                </div>
                                <div class="card-content">
                                    <div class="card-main-title">
                                        <div class="border"></div>
                                        <p class="border-left">答题卡</p>
                                        <p class="border-sub">共<span>{{showList.length}}</span>题</p>
                                    </div>
                                     <div class="box-list">
                                        <div v-for="(item,index) in showList" :key="index" class="box-ul">
                                            <div class="checki" :class="{'checked':ruleForm.resource[index].selectAnswer != null && ruleForm.resource[index].selectAnswer != ''}" @click="handleLeft(index)">
                                                {{index+1}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <navfooter></navfooter>
    </div>
</template>

<script>
import navheader from '../components/NavHeader'
import navfooter from '../components/NavFooter'
import star from "../components/star/index"
// import qs from 'qs'
export default {
    data() {
        return {
            showresultId: this.$route.params.resultId,
            showquestion:{},
            showList: [], // 总的题目
            disabledAnswer: false, // 禁止答题
            ruleForm: { // 考生答案
                resource: [],
            },
            questionArrText: [], // 答题保存的数组
            checkResult: false, // 左侧栏、右侧栏、答题结果栏
            resultId: '',
            isfixTab: false,
            navgatorIndex: 0
        }
    },
    metaInfo: {
        title: '试题详情-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    filters: {
        filterHtml(val) {
            // console.log(val,"过滤")
            return val.replace(/<[^>]+>/g, '');
        }
    },
    components: {
        navheader,
        navfooter,
        star
    },
    created() {
        // this.init();
    },
    mounted() {
        this.getPaper();
        // this.getIputValue();
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleScroll () {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            var offsetTop = document.querySelector('#examContentBox').offsetTop
            if (scrollTop > offsetTop) {
                this.isfixTab = true
            } else {
                this.isfixTab = false
            }
            // console.log(scrollTop,offsetTop)
        },
        
        handleLeft(index) {
            this.navgatorIndex = index;
            this.$el.querySelector(`#id${index}`).scrollIntoView({
                behavior: "instant",  // 平滑过渡
                block:    "start"  // 上边框与视窗顶部平齐。默认值
            });
            // dom滚动位置
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // 获取监听元素距离视窗顶部距离
            var offsetTop = document.getElementById(`id${index}`).offsetTop;
            // 获取监听元素本身高度
            var scrollHeight = document.getElementById(`id${index}`).scrollHeight;

            // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
            // 则表示页面已经滚动到可视区了。
            if (scrollTop >= offsetTop && scrollTop<=(offsetTop+scrollHeight)) {
                // 导航栏背景色选中
                // console.log("到了")
            }
        },
        change() {
            this.$forceUpdate()
        },
        getIputValue() {
            // console.log(this.ruleForm.resource.answer)
        },
        // 获取试卷
        getPaper() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/answer/detail.html',
                data:{
                    resultId: this.showresultId
                },
            }).then((res) => {
                
                this.showList = res.data.result.list;
                this.showquestion = res.data.result;
                // for (let i = 0; i < this.showList.length; i++) {
                //     // console.log(this.showList[i].contList,"遍历")
                //     for(let k = 0;k < this.showList[i].contList.length;k++) {
                //         // console.log(this.showList[i].contList[k].option.replace(/<[^>]+>/g, ''),"遍历")
                //         this.showList[i].contList[k].option.replace(/<[^>]+>/g, '')
                //     }
                // }
                // console.log(this.showList,"遍历2")
                this.ruleForm.resource = this.showList; // 重新赋值

                console.log(this.ruleForm.resource,"测试")
                // if(this.showList.qType == 'multi') {
                //     console.log(this.showList.answer);
                // }
            })
        }
    }
}
</script>
<style lang="scss">
    .el-radio__input.is-disabled+span.el-radio__label {
        color: #333333;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label {
        color: #333333;
    }
    .el-radio__input.is-checked+.el-radio__label {
        color: #409EFF !important;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #409EFF !important;
    }
    .examBox {
        padding-top: 31px;
        padding-bottom: 80px;
        box-sizing: border-box;
        width: 100%;
        min-width: 1200px;
        background: #EEEEEE;
        .examContent {
            .crumbBox {
                margin: 0 auto;
                padding-left: 40px;
                box-sizing: border-box;
                width: 1200px;
                height: 70px;
                line-height: 70px;
                background: #FFFFFF;
                border-radius: 10px;
                p {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    a {
                        color: #666666;
                    }
                    span {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #FF5A74;
                    }
                }
            }
            .titleBox {
                margin: 0 auto;
                width: 1200px;
                height: 122px;
                background: #FFFFFF;
                border-radius: 10px;
                text-align: center;
                padding-top: 27px;
                box-sizing: border-box;
                margin-bottom: 20px;
                .title-top {
                    margin-bottom: 18px;
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
                .title-sub {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    span {
                        margin-right: 18px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            .examContentBox {
                margin: 0 auto;
                width: 1200px;
                display: flex;
                justify-content: space-between;
                .leftBox {
                    width: 915px;
                    background: #F9F9FA;
                    border-radius: 0px 0px 10px 10px;
                    .questionContent {
                        .question-title {
                            padding: 31px 29px;
                            box-sizing: border-box;
                            background: #F9F9FA;
                            p {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                color: #333333;
                                line-height: 30px;
                                .font-bold {
                                    font-weight: bold;
                                }
                            }
                        }
                        .VSTD_box_item_select {
                            padding-top: 30px;
                            padding-left: 50px;
                            box-sizing: border-box;
                            width: 915px;
                            // height: 190px;
                            background: url('../assets/img/paperBg.jpg') no-repeat center;
                            .el-radio {
                                display: block;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                margin-bottom: 25px;
                            }
                            .answer-checkbox {
                                display: block;
                                margin-bottom: 11px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                            .answerBox {
                                display: flex;
                                align-items: center;
                                .answerTitle {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                }
                                .answerText {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                                .answerError {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #F91C1C;
                                }
                            }
                        }
                        .VSTD_box_item_mutil {
                            padding-top: 3px;
                            padding-left: 50px;
                            box-sizing: border-box;
                            width: 915px;
                            // height: 190px;
                            background: #FFFFFF;
                            .el-radio {
                                display: block;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                margin-bottom: 25px;
                            }
                            .answer-checkbox {
                                display: block;
                                margin-bottom: -5px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                        }
                        .answerBox {
                            .answerTop {
                                padding-left: 30px;
                                width: 100%;
                                height: 50px;
                                display: flex;
                                line-height: 50px;
                                .answerLeft {
                                    margin-right: 50px;
                                    p {
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                        .right {
                                            vertical-align: baseline;
                                            color: #2CC22C;
                                        }
                                    }
                                }
                                .answerRight {
                                    p {
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                        .right {
                                            vertical-align: baseline;
                                            color: #2CC22C;
                                        }
                                        .error {
                                            vertical-align: baseline;
                                            color: #F91C1C;
                                        }
                                    }
                                }
                            }
                            .answerContent {
                                width: 100%;
                                background: #ffffff;
                                padding: 45px 31px;
                                box-sizing: border-box;
                                .answerSlide {
                                    margin-bottom: 25px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    p {
                                        margin-bottom: 12px;
                                        display: flex;
                                        align-items: center;
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #999999;
                                        .color {
                                            color: #FA526C;
                                        }
                                        .slide {
                                            margin-right: 12px;
                                        }
                                    }
                                    .span {
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #333333;
                                        line-height: 26px;
                                        display: block;
                                        P {
                                            font-size: 16px;
                                            font-family: Microsoft YaHei;
                                            font-weight: 400;
                                            color: #333333;
                                            line-height: 26px;
                                        }
                                    }
                                }
                            }
                        }
                        .answerTwoBox {
                            background: #ffffff;
                            .answerTop {
                                padding: 29px 31px;
                                box-sizing: border-box;
                                background: #f9f9fa;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                                p {
                                    margin-bottom: 5px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    .answerTwoBoxSpan {
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #333333;
                                    }
                                }
                            }
                            .answerBottom {
                                padding: 29px 31px;
                                box-sizing: border-box;
                                p {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                    line-height: 30px;
                                    span {
                                        font-size: 16px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #333333;
                                    }
                                }
                            }
                        }
                    }
                }
                .suspension {
                    position: fixed;
                    top: 80px;
                    right: 351px;
                    z-index: 99999;
                }
                .rightBox {
                    width: 265px;
                    // background: #FFFFFF;
                    border-radius: 10px;
                    .card-content-list {
                        .timer-box {
                            padding-top: 15px;
                            padding-left: 24px;
                            box-sizing: border-box;
                            margin-bottom: 7px;
                            height: 72px;
                            background: #FFFFFF;
                            border-radius: 10px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            span {
                                color: #FA4B66;
                            }
                        }
                        .card-content {
                            background: #FFFFFF;
                            border-radius: 10px;
                            padding-top: 21px;
                            padding-bottom: 40px;
                            padding-left: 20px;
                            padding-right: 20px;
                            box-sizing: border-box;
                            .card-main-title {
                                padding-bottom: 13px;
                                margin-bottom: 20px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #EEEEEE;
                                .border {
                                    margin-right: 10px;
                                    width: 5px;
                                    height: 20px;
                                    background: #FA4B66;
                                }
                                .border-left {
                                    margin-right: 11px;
                                }
                                .border-sub {
                                    font-size: 12px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #FA4B66;
                                }
                                span {
                                    color: #FA4B66;
                                }
                            }
                            .box-list {
                                margin-bottom: 20px;
                                display: flex;
                                flex-wrap: wrap;
                                .box-ul {
                                    .checki {
                                        width: 30px;
                                        height: 25px;
                                        border: 1px solid #DEDEDE;
                                        border-radius: 3px;
                                        line-height: 25px;
                                        text-align: center;
                                        cursor: pointer;
                                        margin-right: 5px;
                                        margin-bottom: 6px;
                                        font-size: 12px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #666666;
                                    }
                                    .checked {
                                        color: #666666;
                                        background: #DEDEDE;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
</style>